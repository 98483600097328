import React from 'react'
import "./RIP.css";
import { Container, Row, Col, Form, FormControl, Button } from 'react-bootstrap';
import SearchDeathNotices from '../../components/organisms/SearchDeathNotices/SearchDeathNotices';
function RIP() {
  return (
      <div>
          <Container className="rip-main-search">
            <SearchDeathNotices/>
          </Container>
      </div>
  );
}

export default RIP