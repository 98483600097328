/**
 * @prettier
 */
import ArrowUpIcon from "./components/arrow-up"
import ArrowDownIcon from "./components/arrow-down"
import ArrowIcon from "./components/arrow"
import CloseIcon from "./components/close"
import CopyIcon from "./components/copy"
import LockIcon from "./components/lock"
import UnlockIcon from "./components/unlock"

const IconsPlugin = () => ({
  components: {
    ArrowUpIcon,
    ArrowDownIcon,
    ArrowIcon,
    CloseIcon,
    CopyIcon,
    LockIcon,
    UnlockIcon,
  },
})

export default IconsPlugin
