import React from "react";
import { Form, Col } from "react-bootstrap";

const InputField = ({
    xs,
    sm,
    md,
    lg,
    controlId,
    type,
    placeholder,
    onChange,
}) => {
    return (
        <Col xs={xs} sm={sm} md={md} lg={lg}>
            <Form.Group controlId={controlId}>
                <Form.Control
                    onChange={onChange}
                    type={type}
                    placeholder={placeholder}
                    className="py-2"
                />
            </Form.Group>
        </Col>
    );
};

export default InputField;
